import {mapActions, mapGetters, mapMutations} from 'vuex';

import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'chat-registration',
  mixins: [validationMixin],

  data() {
    return {
      payload: {
        first_name: '',
        email: '',
        phone: '',
        password: '',
        avatar: null,
        lang: 'ru',
        utm_medium: '',
        utm_source: '',
        site_url: ''
      },
      validationErrors: {}
    }
  },
  validations: {
    payload: {
      first_name: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
      phone: {
        required,
        minLength: minLength(9),
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
      },
      avatar: {},
    }
  },
  computed: {
    ...mapGetters({
      userRegistration: 'translations/userRegistration',
    }),

    disableButton() {
      return this.payload.first_name === '' || this.payload.email === '' || this.payload.password === '';
    },

    firstNameErrors() {
      let error = [];

      if (!this.$v.payload.first_name.$dirty) {
        return error;
      }

      if (!this.$v.payload.first_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }

      if (!this.$v.payload.first_name.required) {
        error.push(this.$t('validationRequired'))
      }

      if (this.validationErrors.first_name) {
        this.validationErrors.first_name.forEach((row) => {
          error.push(row);
        });
      }

      return error;
    },

    emailErrors() {
      let error = [];

      if (!this.$v.payload.email.$dirty) {
        return error;
      }

      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }

      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }

      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }

      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }

      return error;
    },

    phoneErrors() {
      let error = [];

      if (!this.$v.payload.phone.$dirty) {
        return error;
      }

      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationMin').replace(':count', 9));
      }

      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }

      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        });
      }

      return error;
    },

    passwordErrors() {
      let error = [];

      if (!this.$v.payload.password.$dirty) {
        return error;
      }

      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }

      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }

      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('validationMin').replace(':count', 8));
      }

      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },

    avatarErrors() {
      let error = [];

      return error;
    }
  },

  created() {
    this.setUtm();
  },

  methods: {
    ...mapActions({
      registrationRequest: 'translations/TRANSLATIONS_USER_REGISTRATION_TRIGGER',
    }),

    ...mapMutations({
      changeChatRegistrationAvailabilityStatus: 'system/CHAT_REGISTRATION_POPUP_CHANGE_AVAILABILITY_STATUS',
      changeChatSignInAvailabilityStatus: 'system/CHAT_SIGN_IN_POPUP_CHANGE_AVAILABILITY_STATUS',
      changeChatRestorePasswordAvailabilityStatus: 'system/CHAT_RESTORE_PASSWORD_POPUP_CHANGE_AVAILABILITY_STATUS',
    }),

    navigateToLogin() {
      this.changeChatRegistrationAvailabilityStatus(false);

      setTimeout(() => {
        this.changeChatSignInAvailabilityStatus(true);
      }, 150)
    },
    closePopup(){
      this.changeChatRegistrationAvailabilityStatus(false);
    },
    changePhoneInput(number, info) {
      this.isValidPhone = info.valid;

      if (this.isValidPhone) {
        this.payload.phone = info.number.e164.replace('+', '');
      } else {
        this.payload.phone = number;
      }
    },

    submitHandler() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.registrationRequest(this.payload)
          .then(() => {
            this.changeChatRegistrationAvailabilityStatus(false);
          }).catch(error => {
            let formattedMessage = null;

            if (error && error.response) {
              if (error.response.data.message) {
                formattedMessage = error.response.data.message;
              }

              if (error.response.data.error) {
                formattedMessage = error.response.data.error.message;
              }

              if (error.response.data.errors) {
                Object.keys(error.response.data.errors).forEach(errorKey => {
                  error.response.data.errors[errorKey].forEach((line) => {
                    formattedMessage += `\r\n${line}`;
                  });
                });
              }
            }


            formattedMessage && this.$toasted.error(formattedMessage);
          });
      }
    },

    setUtm() {
      this.payload.utm_medium = this.$route.query.utm_medium;
      this.payload.utm_source = this.$route.query.utm_source;
      this.payload.site_url= window.location.origin+window.location.pathname;
    }
  }
}
